<div class="container-fluid">
  <div class="row">
    <div class="col-4">
      <img class="logo {{isHome ? 'hidden' : ''}}" src="assets/img/logo@0.5x.png" srcset="
            assets/img/logo@0.5x.png,
            assets/img/logo.png 2x,
            assets/img/logo@2x.png 3x
          "
           [routerLink]="['/home']"
      >
    </div>
    <div class="col-8 list-holder">
      <ul class="nav-list">
<!--        <li-->
<!--          *ngFor="let page of navPages"-->
<!--          [routerLink]="['/' + page.url]"-->
<!--          class="{{activePage === page.url ? 'active' : ''}}"-->
<!--        >{{page.name}}</li>-->
      </ul>
    </div>
  </div>
</div>
