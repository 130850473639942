<div class="holder">

  <div class="slide hive" animateOnScroll animationName="active">

    <div class="container slide-content">
      <div class="row">
        <div class="d-none d-md-block col-md-2">
          <img class="story-icon" src="assets/img/story-hive.svg">
        </div>
        <div class="col-12 col-md-10">
          <h1>Hive</h1>
          <p>Like all natural products, the character of honey mirrors the area it's from.</p>
          <p class="personal">Your jar comes from
            <a
              target="_blank"
              [href]="releaseDetails.location.url"
            >{{releaseDetails.location.displayName}} and the surrounding area</a>.
          </p>

<!--          <span>Two hives next to each other might produce different honey depending on the nectar sources they find.</span>-->
<!--          <span>A bee might travel up to 5 miles from their hive to collect the nectar needed to make honey.</span>-->
        </div>

      </div>

    </div>

  </div>

  <div class="slide season" animateOnScroll animationName="active">

    <div class="container slide-content">
      <div class="row">
        <div class="d-none d-md-block col-md-2">
          <img class="story-icon" src="assets/img/story-season.svg">
        </div>
        <div class="col-12 col-md-10">
          <h1>Season</h1>
          <p>Different plants flower throughout the year, creating different flavours and colours of honey.</p>
          <p class="personal">Your jar was collected in {{releaseDetails.date}}.</p>

          <!--      <span>It took almost 900 bees to make your jar of honey, each visiting up to 2,000 flowers a day.</span>-->
          <!--      <span>Bees kept in urban or suburban environments produce more varied honey than those in the countryside.</span>-->
        </div>

      </div>

    </div>

  </div>

  <div class="slide crop" animateOnScroll animationName="active">

    <div class="container slide-content">
      <div class="row">
        <div class="d-none d-md-block col-md-2">
          <img class="story-icon" src="assets/img/story-crop.svg">
        </div>
        <div class="col-12 col-md-10">
          <h1>Crop</h1>
          <p>How much honey a hive produces depends on a fine balance of warm and wet weather.</p>
          <p class="personal">Your jar is one of only {{releaseDetails.totalJars}} from this crop.</p>

          <!--      <span>Commercial honey is usually mixed from hundreds of hives all over the world.</span>-->
          <!--      <span>Raw honey contains small amounts of pollen which helps create its unique flavour.</span>-->
        </div>

      </div>

    </div>

  </div>

  <div class="slide jar" animateOnScroll animationName="active">

    <div class="container slide-content">
      <div class="row">
        <div class="d-none d-md-block col-md-2">
          <img class="story-icon" src="assets/img/story-jar.svg">
        </div>
        <div class="col-12 col-md-10">
          <h1>Jar</h1>
          <p>We believe that we all have a responsibility to look after our environment.</p>
          <p class="personal">Your jar is made from recycled glass, paper, and twine; only the lid is new.<br />
            When you finish your honey, every part of the jar can be recycled.</p>

          <!--      <span>Supporting local beekeeping helps protect honeybees, a vital part of our global food chain.</span>-->
          <!--      <span>We donate 50p from each jar we sell to projects that help protect our planet.</span>-->
        </div>

      </div>

    </div>

  </div>

</div>
