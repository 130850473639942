import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  public releaseNo = '';

  public readStory = () => {
    let releaseNo = this.releaseNo.toUpperCase().trim();
    if (releaseNo.substring(0, 3) === '94.') {
      releaseNo = releaseNo.substring(3);
    }
    releaseNo = this.disambiguate(releaseNo.substring(0, 2)) + this.disambiguateNumeric(releaseNo.substring(2));
    this.router.navigate(['/story/' + releaseNo]);
  }

  public disambiguateNumeric = (segment: string) => {
    segment = segment.toUpperCase().trim();
    if (segment.length > 1) {
      // Recursive call to disambiguate strings containing multiple characters
      let returner = '';
      for (let i = 0; i < segment.length; i++) {
        returner = returner + this.disambiguateNumeric(segment.charAt(i));
      }
      return returner;
    } else {
      const relatedTerms = [
        ['2', 'Z'],
        ['1', 'I'],
        ['9', 'G'],
        ['6', 'G'],
        ['0', 'O'],
        ['3', 'E']
      ];
      for (const pair of relatedTerms) {
        if (segment === pair[0]) {
          return pair[1];
        }
      }
      return segment;
    }
  }

  // Swaps out any letters that might have been mis-read (because numbers were expected in their place)
  public disambiguate = (segment: string) => {
    segment = segment.toUpperCase().trim();
    if (segment.length > 1) {
      // Recursive call to disambiguate strings containing multiple characters
      let returner = '';
      for (let i = 0; i < segment.length; i++) {
        returner = returner + this.disambiguate(segment.charAt(i));
      }
      return returner;
    } else {
      const relatedTerms = [
        ['Z', '2'],
        ['L', '1'],
        ['I', '1'],
        ['S', '5'],
        ['B', '8'],
        ['G', '9'],
        ['T', '7'],
        ['O', '0']
        // Add more potential ambiguities here
      ];
      for (const pair of relatedTerms) {
        if (segment === pair[0]) {
          return pair[1];
        }
      }
      return segment;
    }
  }

  ngOnInit(): void {
  }
}
