<div class="home-container">
  <div class="landing-form">
    <img class="logo" src="assets/img/logo.png" srcset="
        assets/img/logo.png,
        assets/img/logo@2x.png 2x,
        assets/img/logo@3x.png 3x
      ">
    <h1>Responsible Local Honey</h1>
    <h2>Discover Your Jar's Story</h2>

    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">94.</span>
      </div>
      <input type="text" class="form-control" placeholder="Release &#8470;" [(ngModel)]="releaseNo">
      <div class="input-group-append">
        <button class="btn btn-info" (click)="readStory()">Go</button>
      </div>

    </div>

  </div>
</div>
