import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less']
})
export class NavComponent implements OnInit {

  constructor(private router: Router) { }

  public isHome = false;
  public activePage = 'home';

  public navPages = [
    {name: 'Home', url: 'home'},
    {name: 'Contact', url: 'contact'}
  ];

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activePage = event.url.substr(1);
        this.isHome = this.activePage === 'home';
      }
    });
  }

}
