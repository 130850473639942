import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.less']
})
export class StoryComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  // public showHint: any = [];
  // private getRandomInt = () => {
  //   return Math.floor(Math.random() * 2) + 1;
  // }

  public releaseDetails = {
    location: {
      displayName: 'Bordon',
      url: 'https://www.google.com/maps/place/Bordon/@51.1155135,-0.8651934'
    },
    date: 'January 1970',
    totalJars: 0
  };

  ngOnInit(): void {
    this.route.params.subscribe((result) => {
      import('../../releases/' + result.releaseNo + '.json').then((releaseJson) => {
        this.releaseDetails = releaseJson;
      }, () => {
        this.router.navigate(['/home']);
      });
    });
  }

}
